import React, { useEffect, useState } from 'react'
import aboutImg1 from '../../assets/images/services/workplace.png'
import atomIcon from '../../assets/images/Yummy-Technologies-icon-little.png'
import Lottie from 'react-lottie';
//import animationData from '../../assets/lotties/teamwork.json';

const AboutUs = () => {
    const [animationData, setAnimationData] = useState();
    const [isVisible, setIsVisible] = useState(true);

    const listenToScroll = () => {
        let heightToShowFrom = 200;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;

        if (winScroll > heightToShowFrom) {
            if(!isVisible){
                setIsVisible(true);
            }
                 // to limit setting state only the first time         
        }
    };

    useEffect(() => {
        import('../../assets/lotties/teamwork.json').then(setAnimationData);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, []);



    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <section className="overview-area ptb-100 pt-0">
            <div className="container">
                <div className="section-title">
                    <br /><br /><br />
                    <span className="sub-title">
                        <img src={atomIcon} width="5%" alt="features" />
                        Comment ça marche</span>
                    <h2>Faisons de vos idées une réalité</h2>
                    <p>Vous avez une idée? Nous vous guidons pour trouver la solution qui vous convient le mieux et établir la démarche à suivre.</p>
                </div>

                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">suivis personnalisé</span>
                            <h2>Vous nous expliquez votre projet </h2>
                            <p>Vous êtes le chef d'orchestre, nous sommes les musiciens. Nous commençons par discuter ensemble du cahier des charges à établir.</p>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Meeting de lancement</h4>
                                <p>Nous commençons par nous rencontrer en visio ou bien en face to face pour discuter ensemble du projet</p>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Définissez vos choix</h4>
                                <p>Envie d'un blog, d'une rubrique commentaires ou d'un formulaire de contact par email ? Nous intégrons toutes les fonctions dont vous avez besoin.</p>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                        {isVisible &&  <Lottie
                                options={defaultOptions}
                                height="80%"
                                width="80%"
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs