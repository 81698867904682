import React from "react"
import Layout from "../components/App/Layout"
import NavbarTwo from "../components/App/NavbarTwo"
import Footer from "../components/App/Footer"
import MainBanner from "../components/DigitalMarketingAgency/MainBanner"
import FeaturedServices from "../components/DigitalMarketingAgency/FeaturedServices"
import Partner from "../components/DigitalMarketingAgency/Partner"
import AboutUs from "../components/DigitalMarketingAgency/AboutUs"
import Services from "../components/DigitalMarketingAgency/Services"
import FunFacts from "../components/DigitalMarketingAgency/FunFacts"
import Projects from "../components/DigitalMarketingAgency/Projects"
import TeamMembers from "../components/DigitalMarketingAgency/TeamMembers"
import Testimonials from "../components/DigitalMarketingAgency/Testimonials"
import Pricing from "../components/DigitalMarketingAgency/Pricing"
import StartProject from "../components/DigitalMarketingAgency/StartProject"
import OurBlog from "../components/DigitalMarketingAgency/BlogPost"
import GetInTouch from "../components/DigitalMarketingAgency/GetInTouch"

import CookieConsent from "react-cookie-consent";


const Index = () => {

    return (
        <Layout>
            <NavbarTwo />
            <MainBanner />
            {/* <Partner /> */}
            <AboutUs />
            <Services />
            <GetInTouch />
            {/* <FunFacts /> */}
            <FeaturedServices />
            <Projects />
            <TeamMembers />
            {/* <Testimonials /> */}
            {/* <Pricing /> */}
            <StartProject />
            {/* <OurBlog /> */}
            <Footer />
            <CookieConsent
                location="bottom"
                buttonText="D'accord"
                overlay={false}
                style={{ background: "#202A45" }}
                buttonStyle={{ color: "white", background: "#05D9FE", fontSize: "15px" }}
                expires={150}
            >Ce site utilise des cookies pour améliorer votre expérience utilisateur.</CookieConsent>
        </Layout>
    );
}

export default Index