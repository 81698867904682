import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import start1 from '../../assets/images/launch.png'
import shape1 from '../../assets/images/shape/vector-shape4.png'
import shape2 from '../../assets/images/shape/vector-shape5.png'
import Lottie from 'react-lottie';
//import animationData from '../../assets/lotties/launch.json';

const StartProject = () => {
    const [animationData, setAnimationData] = useState();
    

    useEffect(() => {
        import('../../assets/lotties/launch.json').then(setAnimationData);
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <section className="project-start-area bg-color ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12">
                        <div className="project-start-image">
                            {/* <img src={start1} alt="banner" /> */}
                            <Lottie
                                options={defaultOptions}
                                height="100%"
                                width="100%"
                            />
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12">
                        <div className="project-start-content">
                            <h2>Faites décoller votre entreprise!</h2>
                            <p>Commencez par nous expliquer votre projet pour que nous puissions vous apporter la solution qu'il vous faut. </p>
                            <Link to="/contact" className="default-btn">
                                <i className="flaticon-web"></i> C'est parti! <span></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="vector-shape9">
                <img src={shape1} alt="shape" />
            </div>
            <div className="vector-shape10">
                <img src={shape2} alt="shape" />
            </div>
        </section>
    )
}

export default StartProject