import React from 'react'
import { Link } from 'gatsby'
import start1 from '../../assets/images/project-start1.png'
import shape1 from '../../assets/images/shape/vector-shape4.png'
import shape2 from '../../assets/images/shape/vector-shape5.png'

const GetInTouch = () => {
    return (
        <section className="project-start-area bg-color ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    {/* <div className="col-lg-6 col-md-12">
                        <div className="project-start-image">
                            <img src={start1} alt="banner" />
                        </div>
                    </div> */}

                    <div className="col-lg-12 col-md-12">
                        <div className="project-start-content">
                            <h2>Demandez votre devis gratuit maintenant !</h2>
                            <Link to="/contact" className="default-btn">
                                <i className="flaticon-right"></i> Devis gratuit <span></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="vector-shape9">
                <img src={shape1} alt="shape" />
            </div> */}
            <div className="vector-shape10">
                <img src={shape2} alt="shape" />
            </div>
        </section>
    )
}

export default GetInTouch