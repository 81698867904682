import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import team5 from '../../assets/images/team/team5.jpg'
import team6 from '../../assets/images/team/team6.jpg'
import team7 from '../../assets/images/team/team7.jpg'
import yummyTeam from '../../assets/images/team/yummy-team.jpg'
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/teamwork.json';

const TeamMembers = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <section className="scientist-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="banner" /> 
                        L'équipe
                    </span>
                    <h2>Qui sommes nous?</h2>
                    <p>Yummy Technologies est une stratup Namuroise composée de jeunes talents passionnés des nouvelles technologies.</p>
                    
                </div>

                <div className="row">
                    <div className="col-lg-4  center offset-sm-4">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={yummyTeam} alt="banner" />
                            </div>
                            <div className="content">
                                <h3>L'équipe</h3>
                                <span>Suivez-nous sur les réseaux</span>

                                <ul className="social">
                                    <li>
                                        <Link to="https://www.facebook.com/" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-facebook'></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.linkedin.com/" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-linkedin'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={team6} alt="banner" />
                            </div>
                            <div className="content">
                                <h3>Kirk Borne</h3>
                                <span>Data Scientist</span>
                                
                                <ul className="social">
                                    <li>
                                        <Link to="https://www.facebook.com/" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-facebook'></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://twitter.com/" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-twitter'></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.instagram.com/" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-instagram'></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.linkedin.com/" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-linkedin'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-scientist-box">
                            <div className="image">
                                <img src={team7} alt="banner" />
                            </div>
                            <div className="content">
                                <h3>Carla Gentry</h3>
                                <span>Analytical Solutions</span>
                                
                                <ul className="social">
                                    <li>
                                        <Link to="https://www.facebook.com/" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-facebook'></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://twitter.com/" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-twitter'></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.instagram.com/" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-instagram'></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.linkedin.com/" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-linkedin'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default TeamMembers