import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie';
// import webDevData from '../../assets/lotties/web-development.json';
// import launchData from '../../assets/lotties/business-startup.json';


const Services = () => {

    const [webDevData, setWebDevData] = useState();
    const [launchData, setLaunchData] = useState();
    const [isWebDevVisible, setIsWebDevVisible] = useState(false);
    const [isLaunchVisible, setIsLaunchVisible] = useState(false);

    const listenToScroll = () => {

        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;

        if (winScroll > 700) {
            if (!isWebDevVisible) {
                setIsWebDevVisible(true);
            }    // to limit setting state only the first time         

        }

        if (winScroll > 1000) {
            if (!isLaunchVisible) {
                setIsLaunchVisible(true);
            }      // to limit setting state only the first time         

        }
    };

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, []);

    useEffect(() => {
        import('../../assets/lotties/web-development.json').then(setWebDevData);
        import('../../assets/lotties/business-startup.json').then(setLaunchData);
    }, []);


    const webDevAnimation = {
        loop: true,
        autoplay: true,
        animationData: webDevData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const launchAnimation = {
        loop: true,
        autoplay: true,
        animationData: launchData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <section className="overview-area ptb-100 pt-0">
            <div className="container">
                {/* Left Image Style */}
                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            {isWebDevVisible && <Lottie
                                options={webDevAnimation}
                                height="80%"
                                width="80%"
                            />}
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">Développement web sur mesure</span>
                            <h2>Nous développons votre super site</h2>
                            <p>Nous travaillons avec les dernières technologies pour vous fournir un site web à la pointe de la technologie.  </p>

                            <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> Nom de domaine</span></li>
                                <li><span><i className='bx bx-check'></i> Adresse email</span></li>
                                <li><span><i className='bx bx-check'></i> Développeur dédié</span></li>
                                <li><span><i className='bx bx-check'></i> Site optimisé</span></li>
                                <li><span><i className='bx bx-check'></i> Mises à jour d'avancement</span></li>
                                <li><span><i className='bx bx-check'></i> Révisions</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* End Left Image Style */}

                {/* Right Image Style */}
                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">Lancement</span>
                            <h2>Vous êtes en route pour le succès!</h2>
                            <p>Une fois que votre nouveau site Web est en ligne, nous vous aidons à planifier les prochaines étapes pour nous assurer que vous disposez de tout ce dont vous avez besoin pour gagner en notoriété sur le Web. </p>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Hébergement rapide et sécurisé</h4>
                                <p>Un hébergement au top pour un meilleur référencement et une meilleure protection.</p>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Mises à jours</h4>
                                <p>Vous pouvez compter sur nous pour faire évoluer votre site au fil du temp à mesure que vos besoins évoluent. </p>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            {isLaunchVisible && <Lottie
                                options={launchAnimation}
                                height="80%"
                                width="80%"
                            />}
                        </div>
                    </div>
                </div>
                {/* End Right Image Style */}

                {/* Left Image Style */}
                {/* <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            <img src={saas} alt="infrastructure cloud" />
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">Consultance IT</span>
                            <h2>Une infrastructure cloud adpatée</h2>
                            <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>

                            <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> Recommender systems</span></li>
                                <li><span><i className='bx bx-check'></i> Demand prediction</span></li>
                                <li><span><i className='bx bx-check'></i> Omnichannel analytics</span></li>
                                <li><span><i className='bx bx-check'></i> Lead generation</span></li>
                                <li><span><i className='bx bx-check'></i> Dedicated Developers</span></li>
                                <li><span><i className='bx bx-check'></i> 24/7 Support</span></li>
                            </ul>
                        </div>
                    </div>
                </div> */}
                {/* End Left Image Style */}
            </div>
        </section>
    )
}

export default Services